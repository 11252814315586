  import React, { Component } from "react";
  import { Header,Form, Select, Table, Button, Icon, Menu, Message, Modal, Pagination, Input } from 'semantic-ui-react'
  import "./PreAuthorisation.css";
  import { invokeApig} from "../libs/awsLib";
  import { JSONDate, formatDateTime } from "../libs/sharedFunctions";
  import AddressSearch from "./AddressSearch"
  import Cookies from 'universal-cookie';


  const PAGE_SIZE=20;
  const PAGE_SIZE_REQUEST=5;
  const statusCodes={1: 'Passed', 2: 'Failed', 3: 'Referred'}
  export default class PreAuthorisation extends Component {
    constructor(props) {
      super(props);
      this.setAddress=this.setAddress.bind(this);
      this.setHomeAddress=this.setHomeAddress.bind(this);
      this.state = {data: [],
        dataPage: 1,
        importdays: 1,
        newAuthorisation: {utilityid: 1,limitedcompany: false, contractterm: 12, mpxn: null, energisationstatus: 'E'},
        businesstypes: [],
        validationfailures: [],
        showAddModal: false,
        saving: false,
        showRequestPaymentModal: false,
        selectedId: null,
        firstName: '',
        lastName: '',
        email: '',
        paymentFailures:[],
        paymentRequestInProgress: false,
        homeAddressSelected: false,
        multimeter: null,
        meterpointauthorisationdata: null,
        commissiondata: null,
        initialpaymentdata:[],
        showAddMPxNModal: false,
        editMPxNCommission: false,
        meterpointauthorisation: null,
        principletermstype: null,
        showCommissionValues: false,
        editCommissionValues: false,
        multiMeterData:[],
        limitedcompany: false,
        addMPxNPage: 1,
        customerNameSearch: '',
        idSearch: '',
        mpxnSearch: '',
        multiMeterDataFiltered: [],
        initialPaymentValue: 0
      };
    }
       
    componentDidMount()
    {
      this.loadCookies();
      this.loadBusinessTypes();
      this.refreshdata(this.state.importdays);
    }

    async componentWillReceiveProps(newProps)
    {
      //refresh if tpi updated
      if (this.props.tpiid !== newProps.tpiid)
      {
        await this.loadCookies();
        this.setState({data: []})
        this.refreshdata(this.state.importdays, newProps.tpiid)
      }
    }

    loadBusinessTypes = () => {
      invokeApig({path: "/lookups/siccode", method: "GET",  body: null}).then((result)=>{this.setState({businesstypes: result.data})}).catch((err)=>{console.log(err)});      
    }

    async refreshbutton()
    {
      await this.loadCookies();
      this.refreshdata(this.state.importdays);
    }

    refreshdata(importdays, tpiid=this.props.tpiid) 
    {
      
      // preserve selected row
      this.setState({isLoading: true});
      var fromdate=new Date()
      fromdate.setDate(fromdate.getDate()-importdays);
      invokeApig({path: '/api/meterpointauthorisations/prequalifier', method: "GET", queryParams: [tpiid, JSONDate(fromdate)]}).then(results=>
      {
        this.setState({data: results.data.reverse(), isLoading: false})
      })
    }

    loadCookies()
    {
      const cookies=new Cookies();
      const importDaysCookie=cookies.get('sgSign-importdays');
      var importDays=1;
      if (importDaysCookie) {
        importDays=Number(importDaysCookie);
      }
      this.setState({importdays: importDays});
    }
    
      setAuthorisationField(fieldName, value)
      {
        var copyObject=Object.assign({}, this.state.newAuthorisation);
        copyObject[fieldName]=value;
        const validationfailures=this.validateData(copyObject);
        this.setState({newAuthorisation: copyObject, validationfailures: validationfailures})
      }

      getAuthorisationField(fieldName)
      {
        if (fieldName in this.state.newAuthorisation)
        return this.state.newAuthorisation[fieldName]
        
      }
      setAddress(subbuildingname, buildingname, buildingnumber, dependentthoroughfare, thoroughfare, doubledependentlocality, dependentlocality, posttown, county, postcode, formattedaddress)
      {
        var copyObject=Object.assign({}, this.state.newAuthorisation);
        if (subbuildingname)
        copyObject.subbuildingname=subbuildingname;
        if (buildingname)
        copyObject.buildingname=buildingname;
        if (buildingnumber)
        copyObject.buildingnumber=buildingnumber;
        if (dependentthoroughfare)
        copyObject.dependentthoroughfare=dependentthoroughfare;
        if (thoroughfare)
        copyObject.thoroughfare=thoroughfare;
        if (doubledependentlocality)
        copyObject.doubledependentlocality=doubledependentlocality;
        if (dependentlocality)
        copyObject.dependentlocality=dependentlocality;
        if (posttown)
        copyObject.dependentlocality=dependentlocality;
        if (posttown)
        copyObject.posttown=posttown;
        if (postcode)
        copyObject.postcode=postcode;

        const validationfailures=this.validateData(copyObject);
        this.setState({newAuthorisation: copyObject,validationfailures: validationfailures});
      }

      setHomeAddress(subbuildingname, buildingname, buildingnumber, dependentthoroughfare, thoroughfare, doubledependentlocality, dependentlocality, posttown, county, postcode, formattedaddress)
      {
        var copyObject=Object.assign({}, this.state.newAuthorisation);
        if (subbuildingname.startsWith('Flat ')) {
          copyObject.homeflat=subbuildingname;
          copyObject.homebuildingname=buildingname;
        }
        else  {
          if (subbuildingname)
          copyObject.homebuildingname=subbuildingname;
          else
          copyObject.homebuildingname=buildingname;
        }
        copyObject.homebuildingnumber=buildingnumber;
        copyObject.homethoroughfare=thoroughfare;
        copyObject.homelocality=dependentlocality;
        copyObject.homeposttown=posttown;
        copyObject.homepostcode=postcode;

        const validationfailures=this.validateData(copyObject);
        this.setState({newAuthorisation: copyObject,validationfailures: validationfailures, homeAddressSelected: true});
      }


      validateData(newAuthorisation)
      {
        var validationfailures=[];
        if (!newAuthorisation.product)
          validationfailures.push('Product not provided')
        if (!newAuthorisation.mpxn)
        if (newAuthorisation.utilityid===1)
          validationfailures.push('MPAN Core not provided')
        else if (newAuthorisation.utilityid===2)
          validationfailures.push('MPRN not provided')

        // if (!newAuthorisation.posttown)
        //   validationfailures.push('Address not provided')
        if (newAuthorisation.utilityid===1)
          {if(newAuthorisation.energisationstatus)
            if(!['E','D'].includes(newAuthorisation.energisationstatus))
            validationfailures.push('Invalid energisation status provided, must be E or D')}

        if (newAuthorisation.limitedcompany)
        {
          if (!newAuthorisation.limitedcompanynumber)
          validationfailures.push('Company number not provided')
        }
        else
        {
          if (!newAuthorisation.name)
          validationfailures.push('Customer name not provided')

          if (!newAuthorisation.internalsic)
          validationfailures.push('Business type not provided')

          if (!newAuthorisation.firstname)
          validationfailures.push('First name not provided')

          if (!newAuthorisation.surname)
          validationfailures.push('Surname not provided')

          if (!newAuthorisation.homepostcode)
          validationfailures.push('Home post code not provided')

          if (!newAuthorisation.homethoroughfare)
          validationfailures.push('Home street not provided')


          // if (!newAuthorisation.dateofbirth)
          //   validationfailures.push('Date of birth not provided')
        }
        return validationfailures;

      }

    saveAuthorisation()
    {
      var authorisation=this.state.newAuthorisation;
      authorisation.tpiid=this.props.tpiid;
      this.setState({saving: true})
      invokeApig({path: '/api/meterpointauthorisations', method: 'POST', body: authorisation}).then(result=>{this.setState({showAddModal: false, saving: false}); this.refreshdata(this.state.importdays)}).catch(err=>{console.log(err); this.setState({saving: false})})
    }

    resetAddModel()
    {
      this.setState({newAuthorisation: {utilityid: 1,limitedcompany: false, contractterm: 12, mpxn: null}});
    }

    getObjectValue(object, property, defaultValue)
    {
      if (property in object)
      return object[property]
      else
      return defaultValue
    }

    showRequestPayment(meterpointauthorisation)
    {
      console.log('meterpointauthorisation', meterpointauthorisation)
      //var multimeter=this.getObjectValue(meterpointauthorisation, 'principletermstype', '')==="1" ? this.state.multimeter : false;
      var limitedcompany=meterpointauthorisation.limitedcompany==='true' ? true : false;
      this.setState({showRequestPaymentModal: true, paymentFailures:[], selectedId: meterpointauthorisation.id, firstName: this.getObjectValue(meterpointauthorisation, 'firstname', ''), lastName: this.getObjectValue(meterpointauthorisation, 'surname', ''), email: '', principletermstype: this.getObjectValue(meterpointauthorisation, 'principletermstype', ''), paymentRequestInProgress: false, meterpointauthorisationdata: meterpointauthorisation, commissiondata: meterpointauthorisation.commission, initialpaymentdata:[{id: meterpointauthorisation.id, tpiauthorisationid: meterpointauthorisation.tpiauthorisationid, mpxn: meterpointauthorisation.mpxn, commission: meterpointauthorisation.commission, commissionoverride: meterpointauthorisation.commission, edit: false, initialpayment: meterpointauthorisation.initialpayment}], showCommissionValues: this.props.tpi.products[meterpointauthorisation.product].showthirdpartycosts, editCommissionValues: this.props.tpi.products[meterpointauthorisation.product].editthirdpartycosts, limitedcompany: limitedcompany, customerNameSearch: this.getObjectValue(meterpointauthorisation, 'name', '')})
    }

    requestPayment()
    {
      const requestdata={firstname: this.state.firstName, lastname: this.state.lastName, email: this.state.email, initialpaymentdata: this.state.initialpaymentdata}
      this.setState({paymentRequestInProgress: true})
      invokeApig({path: '/api/meterpointauthorisations/'+this.state.selectedId+'/requestinitialpayment', method: 'POST', body: requestdata})
      .then(result=>{
        if (result.status===200)
        {
        this.setState({showRequestPaymentModal: false, paymentRequestInProgress: false,  multimeter: null, firstName: '', lastName: '', email: '', customerNameSearch: '', editMPxNCommission: false});
        this.refreshdata(this.state.importdays);
        }
        else
        this.setState({paymentFailures: result.data, paymentRequestInProgress: false});
        
      })
      .catch(err=>{console.log(err); this.setState({saving: false})})
    }

    handleMultiMeterChange=(e, { value }) =>{
      this.setState({multimeter: value});
    if (value===false)
  {
    var initialpaymentdata=this.state.initialpaymentdata[0]
    this.setState({initialpaymentdata: [initialpaymentdata]})
  }}



    loadMultiMeterData(){
        invokeApig({path: '/api/meterpointauthorisations/prequalifierpassed', method: "GET", queryParams: [this.state.meterpointauthorisationdata.id, this.state.meterpointauthorisationdata.limitedcompanynumber]}).then(results=>
        {
          this.setState({multiMeterData: results.data}, () => {this.searchMPxN();})
        })

      }

    totalCommission(){
      const totalcommission = this.state.initialpaymentdata.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.commissionoverride);
      }, 0);
      return totalcommission.toFixed(2)
    }

    initialPaymentValue(){
      var initialpayment=0
      this.state.initialpaymentdata.map((row, i)=>{
        if(parseFloat(row.initialpayment)>parseFloat(initialpayment))
        {
          initialpayment=parseFloat(row.initialpayment);
        }
      })
      return initialpayment
    }

    toogleEditMPxNCommission(row, value){
      var initialpaymentdata_new= this.state.initialpaymentdata
      initialpaymentdata_new[row].edit=value
      this.setState({initialpaymentdata: initialpaymentdata_new, editMPxNCommission: value})

    }

    updateMPxNCommission(row, value){
      var initialpaymentdata_new= this.state.initialpaymentdata
      if (isNaN(parseFloat(value))){value=0}
      if (value<0){value=0}
      initialpaymentdata_new[row].commissionoverride=value
      this.setState({initialpaymentdata: initialpaymentdata_new})

    }

    removeMPxN(i){
      var initialpaymentdata=this.state.initialpaymentdata
      initialpaymentdata.splice(i, 1)
      this.setState({initialpaymentdata: initialpaymentdata})
    }

    addMPxN(row){
      var initialpaymentdata=this.state.initialpaymentdata
      initialpaymentdata.push({id: row.id, tpiauthorisationid: row.tpiauthorisationid, mpxn: row.mpxn, commission: row.commission, commissionoverride: row.commission, edit: false, initialpayment: row.initialpayment})
      this.setState({initialpaymentdata: initialpaymentdata}, ()=> {this.searchMPxN()});
    }

    searchMPxN(){
      var filterData=[]
      var mpxns=this.state.initialpaymentdata.map((row, i)=>{return row.mpxn})
      this.state.multiMeterData.map((row, i) =>{
        var include=true;
        // not added already
        if (mpxns.includes(row.mpxn)){include=false}
        // id
        if (!(this.state.idSearch==='' || row.tpiauthorisationid.toString().toLowerCase().includes(this.state.idSearch.toString().toLowerCase()))){include=false}
        // mpxn
        if (!(this.state.mpxnSearch==='' || row.mpxn.toString().toLowerCase().includes(this.state.mpxnSearch.toString().toLowerCase()))){include=false}        
        // customer name
        if (!(this.state.customerNameSearch==='' || row.name.toString().toLowerCase().includes(this.state.customerNameSearch.toString().toLowerCase()))){include=false}
        if (include)
        {
            filterData.push(row);
        }
      }
      )
      this.setState({multiMeterDataFiltered: filterData})
    }

    searchMPxNUpdate(fieldname, value){this.setState({[fieldname]: value}, () => {this.searchMPxN();})}

    commissionValueWarning(MPxNs){
      var commissionoverridewarnings=[];
      MPxNs.map((row, i)=>{
        if(parseFloat(row.commission)>parseFloat(row.commissionoverride))
        {
          commissionoverridewarnings.push('Commission for ' + row.mpxn + ' is lower than ' + row.commission);
        }
      })
      return commissionoverridewarnings
    } 

    updateImportDays(data)
    {
      this.setState({importdays: data.value});
      const cookies=new Cookies();
      cookies.set('sgSign-importdays', data.value, { path: '/' });
    }
    
    render() {
      const dateoptions=[{value: 1, text: 'Show files imported in the last day', key: 1}, {value: 7, text: 'Show files imported in the last 7 days', key: 7}, {value: 30, text: 'Show files imported in the last 30 days', key: 30}, {value: 10000, text: 'Show all files', key: 10000}]
      const inlineModalStyle = {
        modal : {
          marginTop: '250px !important',
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        modalpopup : {
          marginTop: '500px !important',
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      };
      const menuItemInline={paddingLeft: "3px", paddingRight: "3px"}

      //handle pagination
      var dataPage=this.state.dataPage
      var data=this.state.data;
      if (data.length<(dataPage-1)*PAGE_SIZE)
      {
        dataPage=1;
        this.setState({dataPage: 1})
      }
      data=data.slice((dataPage-1)*PAGE_SIZE, dataPage*PAGE_SIZE)
      const productOptions=Object.keys(this.props.productdefinitions).filter(productkey=>{return productkey in this.props.tpi.products && this.props.productdefinitions[productkey].requirepreauthorisation}).map(productkey=>{return {key: productkey, value: productkey, text: this.props.productdefinitions[productkey].name}})
      // const businessTypeOptions=Object.keys(this.state.businesstypes).map(businessTypeKey=>{return {key: businessTypeKey, value: businessTypeKey, text: this.state.businesstypes[businessTypeKey]}})
      if (!this.getAuthorisationField('product') && productOptions.length===1)
        this.setAuthorisationField('product', productOptions[0].key)
      
      var addMPxNPage=this.state.addMPxNPage;
      var addMPxNData=this.state.multiMeterDataFiltered;
      addMPxNData=addMPxNData.slice((addMPxNPage-1)*PAGE_SIZE_REQUEST, addMPxNPage*PAGE_SIZE_REQUEST)

      var initialpaymentdatawarnings=this.commissionValueWarning(this.state.initialpaymentdata);
      return (
      <div className='fullpage'> 
      <Modal style={inlineModalStyle.modal} open={this.state.showAddModal} onClose={()=>{this.setState({showAddModal: false})}}>
      <Modal.Header><Header className='seaglassblue'><Icon name='upload'/>Pre-Authorisation Request</Header></Modal.Header>

      <Modal.Content>
      <Form>
        <Form.Select label='Product' placeholder='Product' value={this.getAuthorisationField('product')} options={productOptions} onChange={(event, data)=>{this.setAuthorisationField('product', data.value)}}/>
        <Form.Select label='Utility' placeholder='Utility' value={this.getAuthorisationField('utilityid')} options={[{key:1, value: 1, text: 'Electricity'}, {key:2, value: 2, text: 'Gas'}]} onChange={(event, data)=>{this.setAuthorisationField('utilityid', data.value)}}/>
        <Form.Input type="number" step="1" label={this.getAuthorisationField('utilityid')===1 ? 'MPAN Core' : 'MPRN'} placeholder={this.getAuthorisationField('utilityid')===1 ? 'MPAN Core' : 'MPRN'} value={this.getAuthorisationField('mpxn')} onChange={(event, data)=>{this.setAuthorisationField('mpxn', data.value)}}/>
        {this.getAuthorisationField('utilityid')===1 && <Form.Input label={'Energisation Status'} placeholder='E' value={this.getAuthorisationField('energisationstatus')} onChange={(event, data)=>{this.setAuthorisationField('energisationstatus', data.value)}}/>}
        {/* <AddressSearch label='Address' onChange={this.setAddress} enabled={true}/>          */}
        {/* <Form.Input type="number" step="1" label="EAC/AQ (Optional)" placeholder="EAC/AQ" value={this.getAuthorisationField('eacaq')} onChange={(event, data)=>{this.setAuthorisationField('eacaq', data.value)}}/> */}
        <Form.Input label="Building Name / Number (Optional - use when ECOES / Xoserve lookup fails)" placeholder="Building Name / Number" value={this.getAuthorisationField('addressidentifier')} onChange={(event, data)=>{this.setAuthorisationField('addressidentifier', data.value)}}/>
        <Form.Select label='Contract Term' placeholder='Contract Term' value={this.getAuthorisationField('contractterm')} options={[{key:12, value: 12, text: '12 Months'}, {key:18, value: 18, text: '18 Months'}, {key:24, value: 24, text: '24 Months'}, {key:36, value: 36, text: '36 Months'}]} onChange={(event, data)=>{this.setAuthorisationField('contractterm', data.value)}}/>
        <Form.Checkbox label='Limited Company' placeholder='Limited Company' value={this.getAuthorisationField('limitedcompany')} onChange={(event, data)=>{this.setAuthorisationField('limitedcompany', data.checked)}}/>
        {this.getAuthorisationField('limitedcompany') && <Form.Input label='Company Number' placeholder='Company Number' value={this.getAuthorisationField('limitedcompanynumber')} onChange={(event, data)=>{this.setAuthorisationField('limitedcompanynumber', data.value)}}/>}
        {!this.getAuthorisationField('limitedcompany') && <div>
        <Form.Input label='Customer Name' placeholder='Customer Name' value={this.getAuthorisationField('name')} onChange={(event, data)=>{this.setAuthorisationField('name', data.value)}}/>
        <Form.Select label='Business Type' placeholder='Business Type' value={this.getAuthorisationField('internalsic')} options={this.state.businesstypes} onChange={(event, data)=>{this.setAuthorisationField('internalsic', data.value)}}/>
        <Form.Field><label><b>Owner Details</b></label></Form.Field>
        <Form.Group inline>
        <Form.Input label='First Name' placeholder='First Name' value={this.getAuthorisationField('firstname')} onChange={(event, data)=>{this.setAuthorisationField('firstname', data.value)}}/>
        <Form.Input label='Surname' placeholder='Surname' value={this.getAuthorisationField('surname')} onChange={(event, data)=>{this.setAuthorisationField('surname', data.value)}}/>
        </Form.Group>
        <AddressSearch label='Home Address' onChange={this.setHomeAddress} enabled={true}/>    
        {this.state.homeAddressSelected && <div>
        <Form.Input label='Flat' placeholder='Flat' value={this.getAuthorisationField('homeflat')} onChange={(event, data)=>{this.setAuthorisationField('homeflat', data.value)}}/>
        <Form.Input label='Building Name' placeholder='Building Name' value={this.getAuthorisationField('homebuildingname')} onChange={(event, data)=>{this.setAuthorisationField('homebuildingname', data.value)}}/>
        <Form.Input label='Building Number' placeholder='Building Number' value={this.getAuthorisationField('homebuildingnumber')} onChange={(event, data)=>{this.setAuthorisationField('homebuildingnumber', data.value)}}/>
        <Form.Input label='Street' placeholder='Street' value={this.getAuthorisationField('homethoroughfare')} onChange={(event, data)=>{this.setAuthorisationField('homethoroughfare', data.value)}}/>
        <Form.Input label='District' placeholder='District' value={this.getAuthorisationField('homelocality')} onChange={(event, data)=>{this.setAuthorisationField('homelocality', data.value)}}/>
        <Form.Input label='Town' placeholder='Town' value={this.getAuthorisationField('homeposttown')} onChange={(event, data)=>{this.setAuthorisationField('homeposttown', data.value)}}/>

        </div>}     
        <Form.Input type='date' label='Date of Birth' placeholder='Date of Birth' value={this.getAuthorisationField('dateofbirth')} onChange={(event, data)=>{this.setAuthorisationField('dateofbirth', data.value)}}/>
        {/* <AddressSearch label='Address' onChange={this.setAddress} enabled={true}/>  */}
        
        </div>
        } 
      </Form>
      {this.state.validationfailures.length>0 && <Message error header="Validation failed" list={this.state.validationfailures}/>}
      <Menu secondary>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.saveAuthorisation()}} disabled={this.state.validationfailures.length>0}><Icon name='save'/>Save</Button></Menu.Item>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' loading={this.state.saving} onClick={()=>{this.setState({showAddModal: false}); this.refreshdata(this.state.importdays)}} ><Icon name='cancel'/>Close</Button></Menu.Item>
      </Menu>

      </Modal.Content>
      </Modal> 
      <Menu style={{height: "45px"}} className='menubar' secondary>
        {/* <Menu.Item><Header as='h3' className='seaglassblue'>Recent Uploads</Header></Menu.Item> */}
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.resetAddModel();this.setState({showAddModal: true})}}><Icon name='add'/>New</Button></Menu.Item>
        <Menu.Item primary position='right'><Select label='Import Date' value={this.state.importdays} options={dateoptions} onChange={(event, data)=>{this.updateImportDays(data); this.refreshdata(data.value)}} />    </Menu.Item>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.refreshbutton()}} disabled={this.state.editmode}><Icon name='refresh'/>Refresh</Button></Menu.Item>
        {/* <Menu.Item position='right'><Button onClick={()=>{this.setState({isLoading: true});this.refreshdata(this.state.importdays);}}><Icon name='refresh'/>Refresh</Button></Menu.Item> */}

      </Menu>
      <div basic className="authorisations">
      <Table striped compact>
        <Table.Header>
            <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Date Time</Table.HeaderCell>
            <Table.HeaderCell>Requested By</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>MPxN</Table.HeaderCell>
            <Table.HeaderCell>Term</Table.HeaderCell>
            <Table.HeaderCell>Authorisation</Table.HeaderCell>
            <Table.HeaderCell>Credit Check</Table.HeaderCell>
            <Table.HeaderCell>Payments</Table.HeaderCell>
            <Table.HeaderCell>Payment Status</Table.HeaderCell>
            <Table.HeaderCell>Tariff</Table.HeaderCell>
            <Table.HeaderCell>Prices</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
        {data.map((row, i) =>
        {return <Table.Row key={i}>
                <Table.Cell>{row.tpiauthorisationid}</Table.Cell>
                <Table.Cell>{formatDateTime(new Date(row.datetime))}</Table.Cell>
                <Table.Cell>{row.username}</Table.Cell>
                <Table.Cell>{row.product in this.props.productdefinitions && this.props.productdefinitions[row.product].name + ' ('+(row.utilityid===1 ? 'Elec' : 'Gas')+ ')'}</Table.Cell>
                <Table.Cell>{row.mpxn}</Table.Cell>
                <Table.Cell>{row.contractterm+' months'}</Table.Cell>                
                <Table.Cell>{row.authorisationstatus && (statusCodes[row.authorisationstatus])}{row.authorisationreason && ' ('+row.authorisationreason+ ')'}</Table.Cell>
                <Table.Cell>{row.creditcheckstatus && (statusCodes[row.creditcheckstatus])}{row.creditcheckreason && ' ('+row.creditcheckreason+ ')'}</Table.Cell>
                <Table.Cell>
                {(row.paymentcalculationstatus===1 ? ('First Payment: £'+row.initialpayment.toFixed(2)): (row.paymentcalculationstatus===2 ? 'Failed ('+row.paymentcalculationreason+')':''))}
                {row.initialpaymentstatus===1 && row.linkedtpiauthorisationid ? ' (Requested via ' + row.linkedtpiauthorisationid + ') ' : row.initialpaymentstatus===1 ? ' (Requested) ' : (row.initialpaymentstatus===2 ? ' (Paid) ': (row.initialpaymentstatus===3 ? ' (Declined) ': ' '))} 
                {row.paymentcalculationstatus===1 && row.initialpaymentstatus===null && row.product in this.props.tpi.products && this.props.tpi.products[row.product].requestpaymentonprequalification && <Button className='seaglassbutton' size='tiny' onClick={()=>{this.showRequestPayment(row)}}><Icon name='credit card'/>Request</Button>}
                </Table.Cell>
                <Table.Cell>{row.paymentstatus}</Table.Cell>
                <Table.Cell>{row.pricebookname}</Table.Cell>
                <Table.Cell>{row.tariffprices}</Table.Cell>
                
                </Table.Row>
        })}
            
        </Table.Body>                        
        </Table>
        <Pagination disabled={this.state.editmode} activePage={this.state.dataPage} totalPages={Math.ceil(this.state.data.length/PAGE_SIZE)} onPageChange={(event, { activePage })=>{this.setState({dataPage: activePage})}}/> 
        
      </div>
      <Modal style={inlineModalStyle.modal} open={this.state.showRequestPaymentModal} >
      <Modal.Header><Header className='seaglassblue'><Icon name='credit card'/>Request Initial Payment</Header></Modal.Header>
   
      <Modal.Content>
        <Form>
        <Form.Group inline>
          <label>Type</label>
          <Form.Radio
            label='Single Meter'
            value={false}
            checked={this.state.multimeter===false}
            onChange={this.handleMultiMeterChange}
          />
          <Form.Radio
            label='Multi Meter'
            value={true}
            checked={this.state.multimeter}
            onChange={this.handleMultiMeterChange}
          />
        </Form.Group>
        <Form.Input label='First Name' placeholder='First Name' value={this.state.firstName} onChange={(event, data)=>{this.setState({firstName: data.value})}}/>
        <Form.Input label='Last Name' placeholder='Last Name' value={this.state.lastName} onChange={(event, data)=>{this.setState({lastName: data.value})}}/>
        <Form.Input label='Email' placeholder='Email' value={this.state.email} onChange={(event, data)=>{this.setState({email: data.value})}}/>              
        </Form>
        <div className='mpxnlist'><Table  compact>
        <Table.Header>
            <Table.Row>
            <Table.HeaderCell>MPxN</Table.HeaderCell>
            {(this.state.showCommissionValues && this.state.principletermstype==="1") && <Table.HeaderCell>Commission (£)</Table.HeaderCell> }
            {(this.state.principletermstype==="1" || (this.state.principletermstype!=="1" && this.state.multimeter)) && <Table.HeaderCell>Actions</Table.HeaderCell>}
            </Table.Row>
        </Table.Header>
        <Table.Body>
        {this.state.initialpaymentdata.map((row, i) =>
        {return <Table.Row key={i}>
                <Table.Cell>{row.mpxn}</Table.Cell>
                {(this.state.showCommissionValues && this.state.principletermstype==="1") && <Table.Cell><Form.Input type="number" value={row.commissionoverride} disabled={!row.edit} onChange={(event, data)=>{this.updateMPxNCommission(i, data.value)}}/></Table.Cell>}
                {(this.state.principletermstype==="1" || (this.state.principletermstype!=="1" && this.state.multimeter)) && <Table.Cell>{(!this.state.editMPxNCommission && this.state.editCommissionValues && this.state.showCommissionValues  && this.state.principletermstype==="1") && <Button className='seaglassbutton' icon onClick={()=>{this.toogleEditMPxNCommission(i, true)}}><Icon name='edit'/>Edit</Button>}{row.edit && <Button className='seaglassbutton' icon onClick={()=>{this.toogleEditMPxNCommission(i, false)}}><Icon name='save'/>Save</Button>}{i>0 && <Button className='seaglassbutton' icon onClick={()=>{this.removeMPxN(i)}}><Icon name='trash'/>Delete</Button>}</Table.Cell>} 
                </Table.Row>
        })}
        {this.state.multimeter && <Table.Row>
                <Table.Cell></Table.Cell>
                {(this.state.showCommissionValues && this.state.principletermstype==="1") && <Table.Cell></Table.Cell>}
                <Table.Cell><Button icon className='seaglassbutton' onClick={()=>{this.setState({showAddMPxNModal: true}); this.loadMultiMeterData()}}><Icon name='add'/>Add</Button></Table.Cell>
                </Table.Row>}
        </Table.Body>
        </Table> </div>
        {this.state.principletermstype==="1" ? <div className="totalboxes"> <Input
          label='Total Commission'
          width={6}
          value={'£' + this.totalCommission()}
          disabled
        /> </div>: null}
          <div className="totalboxes"><Input
          label='Initial Payment'
          width={6}
          value={'£' + this.initialPaymentValue()}
          disabled
        /></div>
        {this.state.paymentFailures.length>0 && <Message error header="Payment request failed" list={this.state.paymentFailures}/>}
        {initialpaymentdatawarnings.length>0 && <Message warning header="Commission value warning" list={initialpaymentdatawarnings}/>}
      <Menu secondary>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' loading={this.state.paymentRequestInProgress} disabled={!(this.state.firstName && this.state.lastName && this.state.email && this.state.multimeter!==null && !this.state.editMPxNCommission && (!(this.state.multimeter && this.state.initialpaymentdata.length===1) || !this.state.multimeter))} onClick={()=>{this.requestPayment()}}><Icon name='credit card'/>Request Payment</Button></Menu.Item>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.setState({showRequestPaymentModal: false, paymentRequestInProgress: false, multimeter: null, firstName: '', lastName: '', email: '', customerNameSearch: '', editMPxNCommission: false})}}><Icon name='x'/>Close</Button></Menu.Item>
      </Menu>
      </Modal.Content>
      <Modal style={inlineModalStyle.modal} open={this.state.showAddMPxNModal}>
      <Modal.Header><Header className='seaglassblue'><Icon name='add'/>Add MPxN</Header></Modal.Header>
   
      <Modal.Content>
        <Form>
        <Form.Group inline>
        {!this.state.limitedcompany && <Form.Input label='Customer Name Search' placeholder='Customer Name' value={this.state.customerNameSearch} onChange={(event, data)=>{this.searchMPxNUpdate('customerNameSearch', data.value)}}/>}
        <Form.Input label='Id Search' placeholder='Id' value={this.state.idSearch} onChange={(event, data)=>{this.searchMPxNUpdate('idSearch', data.value)}}/>
        <Form.Input label='MPxN Search' placeholder='MPxN' value={this.state.mpxnSearch} onChange={(event, data)=>{this.searchMPxNUpdate('mpxnSearch', data.value)}}/>
        </Form.Group>
        </Form>
        <Table compact>
        <Table.Header>
            <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>MPxN</Table.HeaderCell>
            {!this.state.limitedcompany && <Table.HeaderCell>Customer Name</Table.HeaderCell>}
            {!this.state.limitedcompany && <Table.HeaderCell>First Name</Table.HeaderCell>}
            {!this.state.limitedcompany && <Table.HeaderCell>Surname</Table.HeaderCell>}
            <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
        {addMPxNData.map((row, i) =>
        {return <Table.Row key={i}>
                <Table.Cell>{row.tpiauthorisationid}</Table.Cell>
                <Table.Cell>{row.mpxn}</Table.Cell>
                {!this.state.limitedcompany && <Table.Cell>{row.name}</Table.Cell>}
                {!this.state.limitedcompany && <Table.Cell>{row.firstname}</Table.Cell>}
                {!this.state.limitedcompany && <Table.Cell>{row.surname}</Table.Cell>}
                <Table.Cell><Button className='seaglassbutton' icon onClick={()=>{this.addMPxN(row)}}><Icon name='add'/>Add</Button></Table.Cell>    
                </Table.Row>
        })}
        </Table.Body>
        </Table>
        <Pagination activePage={this.state.addMPxNPage} totalPages={Math.ceil(this.state.multiMeterDataFiltered.length/PAGE_SIZE_REQUEST)} onPageChange={(event, { activePage })=>{this.setState({addMPxNPage: activePage})}}/> 
      <Menu secondary>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.setState({showAddMPxNModal: false, mpxnSearch:'', idSearch: ''})}}><Icon name='x'/>Close</Button></Menu.Item>
      </Menu>
      </Modal.Content>      
      </Modal> 
      </Modal> 
      </div>

      );
    }
  }