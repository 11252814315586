const stage="training";

const gatewayURLs={
  dan: "https://2hxl6suv4g.execute-api.eu-west-2.amazonaws.com/dan",
  dev: "https://l9cj189rh5.execute-api.eu-west-2.amazonaws.com/dev",
  test: "https://a6a56ya2q5.execute-api.eu-west-2.amazonaws.com/test",
  training: "https://zk8tyuqqo6.execute-api.eu-west-2.amazonaws.com/training", 
  production: "https://s4jz7vx0i7.execute-api.eu-west-2.amazonaws.com/production", 
  conradtest: "https://99ku1jzf2g.execute-api.eu-west-2.amazonaws.com/conradtest",
  omnitest: "https://xg1cqbagx0.execute-api.eu-west-2.amazonaws.com/omnitest",
  omniprod: "https://hb060r1nsi.execute-api.eu-west-2.amazonaws.com/omniprod", 
  conradprod: "https://4lb4uqm5e6.execute-api.eu-west-2.amazonaws.com/conradprod", 
  conraduat: "https://jd1gle6x9k.execute-api.eu-west-2.amazonaws.com/conraduat",
  omniuat: "https://80dev0efwi.execute-api.eu-west-2.amazonaws.com/omniuat", 
  demo: "https://503ektqy3d.execute-api.eu-west-2.amazonaws.com/demo", 
  unifytest: "https://97n2vwpql3.execute-api.eu-west-2.amazonaws.com/unifytest", 
  unifyprod: "https://1zp1hhvlrc.execute-api.eu-west-2.amazonaws.com/unifyprod", 
  unifyuat: "https://tm7m1cyw7l.execute-api.eu-west-2.amazonaws.com/unifyuat",
  greentest: "https://b0mkf8ovk5.execute-api.eu-west-2.amazonaws.com/greentest",
  greenuat: "https://5844df1t42.execute-api.eu-west-2.amazonaws.com/greenuat",
  greenprod: "https://ruajhxwtd8.execute-api.eu-west-2.amazonaws.com/greenprod"
};

const billURLs={
  dan: "https://4z9c4e3qsf.execute-api.eu-west-2.amazonaws.com/devmajor",
  dev: "https://n7shjzjhp1.execute-api.eu-west-2.amazonaws.com/dev",
  test: "https://ma006y4e03.execute-api.eu-west-2.amazonaws.com/test",
  training: "https://299fy528pk.execute-api.eu-west-2.amazonaws.com/training", 
  production: "https://y5d8016hyl.execute-api.eu-west-2.amazonaws.com/production", 
  conradtest: "https://i387ad1ty0.execute-api.eu-west-2.amazonaws.com/conradtest", 
  omnitest: "https://k9qsbwfzol.execute-api.eu-west-2.amazonaws.com/omnitest",
  omniprod: "https://bjylozft4d.execute-api.eu-west-2.amazonaws.com/omniprod", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "https://m21qkl11n3.execute-api.eu-west-2.amazonaws.com/omniuat", 
  demo: "https://5m8h79ol3a.execute-api.eu-west-2.amazonaws.com/demo", 
  unifytest: "https://x6mc4mhmkl.execute-api.eu-west-2.amazonaws.com/unifytest", 
  unifyprod: "https://wgmok59t8a.execute-api.eu-west-2.amazonaws.com/unifyprod", 
  unifyuat: "",
  greentest: "",
  greenuat: "",
  greenprod: ""
};

const commsURLs={
  dan: "https://v1ujqx2698.execute-api.eu-west-2.amazonaws.com/dev",
  dev: "https://v1ujqx2698.execute-api.eu-west-2.amazonaws.com/dev/",
  test: "https://akznb8tkt4.execute-api.eu-west-2.amazonaws.com/test",
  training: "https://ia2zwspbp9.execute-api.eu-west-2.amazonaws.com/training", 
  production: "https://9cl37kucj9.execute-api.eu-west-2.amazonaws.com/production", 
  conradtest: "", 
  omnitest: "https://r1kzce7sc6.execute-api.eu-west-2.amazonaws.com/omnitest",
  omniprod: "https://zoehhjytxi.execute-api.eu-west-2.amazonaws.com/omniprod", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "https://1zdrl8g461.execute-api.eu-west-2.amazonaws.com/omniuat", 
  demo: "https://akznb8tkt4.execute-api.eu-west-2.amazonaws.com/test", 
  unifytest: "https://zd1tt8js7g.execute-api.eu-west-2.amazonaws.com/unifytest", 
  unifyprod: "https://03uwi8wnql.execute-api.eu-west-2.amazonaws.com/unifyprod", 
  unifyuat: "",
  greentest: "",
  greenuat: "",
  greenprod: ""
};

const billFrontEndURLs={
  dan: "",
  dev: "",
  test: "https://test.sgbill.seaglasscloud.com",
  training: "https://sgbilltraining.valda.seaglasscloud.com", 
  production: "https://sgbill.valda.seaglasscloud.com", 
  conradtest: "", 
  omnitest: "https://omnitest.sgbill.seaglasscloud.com",
  omniprod: "https://omni.sgbill.seaglasscloud.com", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "https://omniuat.sgbill.seaglasscloud.com", 
  demo: "", 
  unifytest: "https://unifytest.sgbill.seaglasscloud.com", 
  unifyprod: "https://unify.sgbill.seaglasscloud.com", 
  unifyuat: "", 
  greentest: "",
  greenuat: "",
  greenprod: ""
};

const userPoolIds={
  dan: "eu-west-2_kWyJkAJXK",
  dev: "eu-west-2_kWyJkAJXK",
  test: "eu-west-2_kWyJkAJXK",
  smarttest: "eu-west-2_kWyJkAJXK",
  training: "eu-west-2_U1hd34rO5", 
  production: "eu-west-2_mV9Lj7t2T", 
  conradtest: "eu-west-2_UdlHufq9g", 
  omnitest: "eu-west-2_os0xm7TOW", 
  omniprod: "eu-west-2_fhFeoUNzn", 
  conradprod: "eu-west-2_P2aotLbqM", 
  conraduat: "eu-west-2_QKJOdLZzO", 
  omniuat: "eu-west-2_zAqlPDDJT", 
  demo: "eu-west-2_VATSUpeDZ",
  unifytest: "eu-west-2_tX4LJ5va9", 
  unifyprod: "eu-west-2_ej8IDWRbr", 
  unifyuat: "eu-west-2_BA03U0sor",
  greentest: "eu-west-2_943DXPRqH",
  greenuat: "eu-west-2_lgtw9bKqg",
  greenprod: "eu-west-2_wRAtDQY4t"
};

const appClientIds={
  dev: "lj9rvmobt48m2lg124j5d0sho",
  dan: "lj9rvmobt48m2lg124j5d0sho",
  test: "lj9rvmobt48m2lg124j5d0sho",
  smarttest: "lj9rvmobt48m2lg124j5d0sho",
  training: "1a05jst02r67oqs779mobt3v4c", 
  production: "4hbtasb3doa2mc2nckj1u9s11e", 
  conradtest: "73ctl2u62rbqsrm4unqsivfb4a", 
  omnitest: "7iafrragmdiunn0koilht53hsn", 
  omniprod: "2pahv5qp6evvhbpfi8gjqmpoqr", 
  conradprod: "65sqrfppv4g2kmk4st9qgcndkj",
  conraduat: "3raes5msi5nlpe05ihsngh0oto", 
  omniuat: "1afasibmi2jcqtu1ieh1k0cmmm", 
  demo: "7lqfrtugkbesjplo04220eqf0t",
  unifytest: "3r0afvjqvllru9rei8nkbc92h4", 
  unifyprod: "7qn1mqnfq8plr26gh61ts695ea", 
  unifyuat: "15u68ijj49cmaq6037u4sj10lf",
  greentest: "5vefeneffb5fub5hj8rmop1omr",
  greenuat: "dnf15spvt5tdrfvcvtkt3717n",
  greenprod: "552es7uqgco9d54r6ufqh6v2fd"
};

const awsEnv={
dev: "sg",
dan: "sg",
test: "sg",
training: "valda",
production: "valda", 
conradtest: "sg", 
omnitest: "sg", 
omniprod: "sg", 
conradprod: "sg", 
conraduat: "sg", 
omniuat: "sg", 
demo: "sg", 
unifytest: "sg", 
unifyprod: "sg", 
unifyuat: "sg",
greentest: "sg",
greenuat: "sg",
greenprod: "sg"
};

const identityPoolIds={
  dev: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  dan: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  test: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  smarttest: "eu-west-2:4f8b16cb-7a2e-4f44-a1f1-d657b4a494b9",
  training: "eu-west-2:9a93047c-21f6-4d16-a4dd-a0e9b3abe967", 
  production: "eu-west-2:af490153-bdd7-4d34-96d8-9e437f34d62f", 
  conradtest: "eu-west-2:68af4497-ae15-47ac-aa88-5dc352edef45", 
  omnitest: "eu-west-2:e659a7bf-b8fc-4956-b230-dcf9cee88e51", 
  omniprod: "eu-west-2:ebd3661d-8600-41f3-a2c6-0cdcf1f59050", 
  conradprod: "eu-west-2:33d3c1c0-4ed7-45a2-8ddf-e4f509584b27",
  conraduat: "eu-west-2:1c36e85b-4f0d-4afa-a775-4a59d4891368", 
  omniuat: "eu-west-2:c409fa15-e20a-4427-9d24-90a6adf16140", 
  demo: "eu-west-2:0be22033-6661-4bf7-ba7a-b50c9eebc6dd", 
  unifytest: "eu-west-2:e6dbb588-24af-4092-8a00-f0c7f9b17e80", 
  unifyprod: "eu-west-2:a54034ad-f880-4a2f-a440-241ae9f0e0e0", 
  unifyuat: "eu-west-2:6d857722-aae9-44b2-ad51-b2990d4d85d8",
  greentest: "eu-west-2:c9fcff0f-13dc-47fd-b880-ef7fafb07c43",
  greenuat: "eu-west-2:a080f614-da87-4a73-a96c-d299b28c1c63",
  greenprod: "eu-west-2:2e67f195-3ee9-4b13-baa0-3e28e6a9ea5d"
};

const getAddressAPIKeys={
  dev: "9IAFrcnkskGk5MaEPdp5Lw20461",
  dan: "9IAFrcnkskGk5MaEPdp5Lw20461",
  test: "9IAFrcnkskGk5MaEPdp5Lw20461",
  training: "9IAFrcnkskGk5MaEPdp5Lw20461",
  production: "9IAFrcnkskGk5MaEPdp5Lw20461", 
  conradtest: "9IAFrcnkskGk5MaEPdp5Lw20461", 
  omnitest: "yg61qYqEQkyVAkUywQ7FBA27885", 
  omniprod: "7_Ouw8IzXEGgyFoLSvC4og28461", 
  conradprod: "", 
  conraduat: "", 
  omniuat: "yg61qYqEQkyVAkUywQ7FBA27885", 
  demo: "9IAFrcnkskGk5MaEPdp5Lw20461", 
  unifytest: "pe74SsECtE-NnARCtBso2w32914", 
  unifyprod: "pe74SsECtE-NnARCtBso2w32914", 
  unifyuat: "pe74SsECtE-NnARCtBso2w32914" ,
  greentest: "pe74SsECtE-NnARCtBso2w32914",
  greenuat: "pe74SsECtE-NnARCtBso2w32914",
  greenprod: "pe74SsECtE-NnARCtBso2w32914"
}

const cookieURLs={
    training: "https://www.valdaenergy.com/cookie-policy",
    production: "https://www.valdaenergy.com/cookie-policy", 
  }
  
const eSignStages=["unifyuat", "unifytest", "unifyprod"]

export default {
    mfa: false, 
    stage: stage, 
    applicationid: 2, 
    s3uploadbucket: 'seaglass.'+stage+'.comms.upload', 
    s3documentsbucket: stage+'.sgsign.downloads', 
    s3uploadfolder: 'sgInteract', 
    aws: {
      // accessKeyId: accessKeyIds[stage],
      // secretAccessKey: secretAccessKeys[stage], 
      region: 'eu-west-2'
    },     
    cognito: {
      USER_POOL_ID: userPoolIds[stage],
      APP_CLIENT_ID: appClientIds[stage], 
      REGION: "eu-west-2",
      IDENTITY_POOL_ID: identityPoolIds[stage],
    },
    getAddress: {
      URL: "api.getAddress.io", 
      APIKey: getAddressAPIKeys[stage]
    },     
    apiGateway: {URL: gatewayURLs[stage], REGION: "eu-west-2"},
    cookieURL: cookieURLs[stage],
    eSign: (eSignStages.indexOf(stage)>-1)
  };
